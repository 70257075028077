import React, { createContext, useState } from 'react'
import getAxios from '../axios/Axios'
import request from '../request/request'
import moment from 'moment/moment';
const UserContext = createContext({
    userData: [],
    whoamiFunc: () => {
    },
    login: false,
    tagFilter: [],
    setTagFilter: () => { },
    timeConvert: () => { },
    filterString : ()=>{}
})
function UserProvider({ children }) {
    const [data, setData] = useState([])
    const [userLogin, setUserLogin] = useState(false)
    const [recName, setRecName] = useState(null)
    const [tagFilter, setTagFilter] = useState([])

    const clearFilter = (clean, isClear) => {
        if (isClear)
            clean()
    }


    const whoamiFunction = async () => {
        console.log("whoami called...")
        try {
            const res = await getAxios(request.who_ami)
            if (res.data.apiresponse.code == "LOGGEDIN") {
                setData(res.data)
                dispatch({ type: "login", login: true })
            } else {
                dispatch({ type: "login", login: false })
            }
        } catch {
            setUserLogin(false)
        }
    }

    const timeConvert = (e) => {
        // console.log(e,"eee");
        return moment(e).fromNow()
    }

    const filterString = (str) => {

        let temp = str.split("\n")
        temp = temp.filter(function (e) { return e });

        return temp;
        // console.log("record", record, temp.map(str => {
        //     return ((`${str}`))
        // }
    }

    const UserContextValue = {
        userData: data,
        whoamiFunc: whoamiFunction,
        login: userLogin,
        setTagFilter: setTagFilter,
        tagFilter: tagFilter,
        clearFilter,
        timeConvert: timeConvert,
        filterString:filterString
        //  removeFilterItem: removeFilterItem,

    }
    return (
        <UserContext.Provider value={UserContextValue}>
            {children}
        </UserContext.Provider>
    )
}

export default UserProvider
export { UserContext }