import axios from 'axios'
import React, { Component, Suspense, useContext, useEffect, useState } from 'react'
import { HashRouter, BrowserRouter, Route, Routes } from 'react-router-dom'
import request from './components/request/request'
import { useNavigate } from 'react-router-dom'
import './scss/style.scss'
import getAxios, { postAxios } from './components/axios/Axios'
import { Navigate } from 'react-router-dom'
import "./App.scss"
import { ToastContainer } from 'react-toastify'
import { useDispatch, useSelector } from 'react-redux'
import HomePage from "../src/views/pages/HomePage/HomePage"
import UserProvider, { UserContext } from './components/context/UserProvider';
import "../src/styles/global.scss"
import Loader from "../src/components/loader/Loader"
// import TicketSummary from './views/base/ticket-summary/TicketSummary'
import ChangePassword from "./views/pages/ChangePassword/ChangePassword"
import { getQueryString } from './components/common/helper'
import { ListAppliedContext } from './components/context/ListAppliedFilterProvider'
const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))
// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))
function App() {
  const [loginStatus, setLoginStatus] = useState(false)
  const { loader, login } = useSelector((state) => state)
  const dispatch = useDispatch()
  const UserData = useContext(UserContext)
  const { filter } = useContext(ListAppliedContext)
  const loginCheck = async () => {
    const res = await getAxios(request.who_ami)
    if (res?.data?.apiresponse?.code == "LOGGEDIN") {
      dispatch({ type: "login", login: true })
    } else {
      dispatch({ type: "login", login: false })
      window.location.href = "/#/login"
    }
  }
  useEffect(() => {
    loginCheck()
    fetchStatusData()
    fetchStatusCountData()
    fetchCareerData()
    UserData.whoamiFunc()
  }, [])
  const fetchCareerData = async () => {
    const params = {
      "page": "1",
      "rows": "1000",
      more_details: "0",
      send_all: 1
    }
    const res = await postAxios(request.jobCareer, params)
    dispatch({ type: "career_data", careerData: res?.data })
  }

  const fetchStatusCountData = async () => {
    const params = {
      job_id: filter?.jobId || "",
      recruitment_drive_id: filter?.recId || ""
    }
    const res = await getAxios(request.statusCount + "?" + getQueryString(params))
    dispatch({ type: "status_count_data", statusCountData: res?.data?.records })
  }

  const fetchStatusData = async () => {
    const res = await postAxios(request.statusList)
    dispatch({ type: "status_data", statusData: res?.data })
  }
  useEffect(() => {
    dispatch({ type: "loader", loader: true })
    setTimeout(() => {
      dispatch({ type: "loader", loader: false })
    }, 1000)
  }, [])
  if (loader) {
    return (
      <Loader />
    )
  }
  return (
    <HashRouter>
      <ToastContainer
        position="top-right"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="light"
      />

      <div className='' style={{ opacity: loader ? 0.5 : 1 }}>
        <Suspense fallback={loading}>
          <Routes fallback={loading}>
            {login && (
              <>
                <Route exact path="/register" fallback={loading} name="Register Page" element={<Register />} />
                <Route exact path="/404" fallback={loading} name="Page 404" element={<Page404 />} />
                <Route exact path="/500" fallback={loading} name="Page 500" element={<Page500 />} />
                <Route exact path="/login" fallback={loading} name="Login Page" element={<Login />} />
                <Route exact path="/homepage" fallback={loading} name="Home Page" element={<HomePage />} />
                <Route path="*" name="Home" fallback={loading} element={<DefaultLayout />} />
                <Route path='/change-password' fallback={loading} element={<ChangePassword />} />
              </>)}

            {!login && <Route path="*" name="Login Page" fallback={loading} element={<Login />} />}
          </Routes>
        </Suspense>
      </div>
    </HashRouter >
  )
}

export default App
