import React from 'react'
import { AiOutlineSearch, AiFillCaretDown, AiOutlineMenu } from "react-icons/ai"
import { BiSolidUser } from "react-icons/bi"
function SrifinHeader() {
    return (
        <div className='navbar12'>
            <div className='container'>
                <div className='desktop__nav'>
                    <div className='srifin__logo d-flex align-items-center'>
                        <img className='h-100' src='/srifin_final.svg' />
                        <div className='search-box d-none d-lg-block'>
                            <input type='text' className='inputsearch' placeholder='Search by anything' />
                            <div className='search__icon'>
                                <AiOutlineSearch size={22} />
                            </div>
                        </div>
                    </div>
                    <div className='d-block d-lg-none'>
                        <AiOutlineMenu size={25} />
                    </div>
                    <div className='d-none d-lg-block'>
                        <div className='header__right '>
                            <div className='header__para'>
                                Courses&nbsp;
                                <AiFillCaretDown />
                            </div>
                            <div className='header__para'>
                                Mobile app
                            </div>
                            <button className='btn blue-color'>Get Started</button>
                            <div className='user__icon'>
                                <BiSolidUser size={18} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default SrifinHeader