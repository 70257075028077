const { createContext } = require("react");
import React, { useState } from 'react'
const ListAppliedContext = createContext({
    filter: {},
    setFilter: () => {

    }
})

function ListAppliedFilterProvider({ children }) {
    const [filter, setFilter] = useState({
    })
    const listContextValue = {
        filter,
        setFilter
    }
    return (
        <div>
            <ListAppliedContext.Provider value={listContextValue}>
                {children}
            </ListAppliedContext.Provider>
        </div>
    )
}

export default ListAppliedFilterProvider
export { ListAppliedContext }