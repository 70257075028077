import React from 'react'
import Header from "../../../components/srifin/Header"
import { useNavigate } from 'react-router-dom'

function HomePage() {
    const navigate = useNavigate()
    return (
        <div className='srifin__homepage'>
            <Header />
            <div className='custom__container'>
                <div className='row '>
                    <div className='col-12 col-md-6 px-0'>
                        <img className='w-100 app__left' src='/hrms-bg.webp' />
                    </div>
                    <div className='col-12 col-md-6 px-0'>
                        <div className='app__right'>
                            <div className='app__heading'>
                                Login using your account on:
                            </div>
                            <div className='app__para'>
                                Please enter your HRMS credentials to access your personalized dashboard.
                                LMS dashboard provides you with a centralized hub for managing the courses
                                and access various reports/insights.
                            </div>
                            <div>
                                <button className='app__button' onClick={() => navigate("/login")}>Login via <b>HRMS</b></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HomePage