import { createStore } from 'redux'

const initialState = {
  sidebarShow: true,
  loader: false,
  login: true,
  innerLoader: false,
  statusData: [],
  statusCountData: [],
  careerData: [],
  headingData: ""
}
const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    case "loader":
      return { ...state, ...rest }
    case "login":
      return { ...state, ...rest }
    case "innerLoader":
      return { ...state, ...rest }
    case "status_data":
      return { ...state, ...rest }
    case "status_count_data":
      return { ...state, ...rest }
    case "career_data":
      return { ...state, ...rest }
    case "heading_data":
      return { ...state, ...rest }
    default:
      return state
  }
}
const store = createStore(changeState)
export default store
