let BaseUrl2 = process.env.REACT_APP_BASE_URL
let BaseUrl = process.env.REACT_APP_BASE_URL
// const BaseUrl = "https://erpdemo.easyerp.biz/"
const request = {
    login_user: BaseUrl2 + "account/verify_user",
    jobCareer: BaseUrl2 + "hrms/list_job_career",
    jobApplicant: BaseUrl2 + "hrms/list_job_applications",
    who_ami: BaseUrl2 + "account/whoami",
    log_out: BaseUrl2 + "account/logoff",
    jobApplicationDownload: BaseUrl2 + "hrms/download_job_career_qr",
    getGeoLocation: BaseUrl2 + "utils/geo_child",
    downloadFile: BaseUrl2 + "hrms/download_candidate_document",
    updateApplication: BaseUrl2 + "hrms/update_application_status",
    statusData: BaseUrl2 + "hrms/allowed_statuses",
    statusList: BaseUrl2 + "utils/status_list",
    statusCount: BaseUrl2 + "hrms/get_application_status_count",
    positionTypes: BaseUrl2 + "utils/list_position_type",
    employeePositionType: BaseUrl2 + "yancy/api/EmplPositionType",
    addJobPosting: BaseUrl2 + "hrms/add_or_update_job_posting",
    departmentList: BaseUrl2 + "department/department_list",
    createDepartment: BaseUrl2 + "department/create_department",
    deleteDepartment: BaseUrl2 + "department/delete_department",
    updateDepartment: BaseUrl2 + "department/update_department",
    changePassword: BaseUrl2 + "account/change_password",
    departmentList: BaseUrl2 + "department/department_list",
    deleteApplicant: BaseUrl2 + "utils/delete_applicant",
    getExelFile: BaseUrl2 + "hrms/export_applications",
    getExcelFileTracking: BaseUrl2 + "hrms/export_application_transition",
    employeeList: BaseUrl2 + "hrms/employee_list",
    bvcCheckDetails: BaseUrl2 + "hrms/bvc_check_details",
    offerLetterTemplate: BaseUrl2 + "hrms/offer_letter_templates",
    salaryBeakpoints: BaseUrl2 + "yancy/api/SalaryBreakupTemplate",
    breakpointsList: BaseUrl2 + "hrms/salary_breakup_list",
    getOfferLetterData: BaseUrl2 + "hrms/get_offer_letter_data",
    positionTypes: BaseUrl2 + "utils/list_position_type",
    geo_child: BaseUrl2 + "geo/list_job_req_locations",
    click2call: BaseUrl2 + "calls/click2call",
    branchList: BaseUrl2 + "hrms/branch_list",
    bulkApplication: BaseUrl2 + "hrms/bulk_application_actions",
    getCommunicationEvent: BaseUrl2 + "hrms/get_communication_events",
    getStatusHistory: BaseUrl2 + "hrms/list_application_transition",
    reInitiateCommunication: BaseUrl2 + "hrms/reinitiate_communication_event",
    holidayMaster: BaseUrl2 + "yancy/api/HolidayMaster",
    updateJoiningDateMater: BaseUrl2 + "hrms/update_joining_dates_master",
    joiningDate: BaseUrl2 + "yancy/api/JoiningDate",
    holidayCalendar: BaseUrl2 + "yancy/api/HolidayCalendar",
    updateHoliday: BaseUrl2 + "hrms/update_holiday",
    holidayDates: BaseUrl2 + "yancy/api/HolidayCalendar",
    updateJobPosting: BaseUrl2 + "hrms/update_application_job_posting",
    recuritmentDrive: BaseUrl2 + "hrms/recruitment_drives",
    addRecuritmentDrive: BaseUrl2 + "hrms/add_recruitment_drive",
    localitySearch: BaseUrl2 + "geo/list_locality",
    reinitiateBvc: BaseUrl2 + "hrms/reinitiate_bvc",
    reinitiateMissingDoc: BaseUrl2 + "hrms/reinitiate_missing_docs_mail",
    statusListItem: BaseUrl2 + "yancy/api/StatusItem",
    employeementData: BaseUrl2 + "yancy/api/Enumeration",
    // localitySearch: BaseUrl + "geo/list_locality",
    createOrgBranch: BaseUrl2 + "hrms/create_organisation_branch",
    kycRejectData: BaseUrl2 + "yancy/api/Enumeration",
    updateJoiningDate: BaseUrl2 + "hrms/update_joining_date",
    statusItem: BaseUrl2 + "yancy/api/StatusItem",
    updateStatus: BaseUrl2 + "hrms/update_status",
    applicationData: BaseUrl2 + "account/application_data",
    branchList: BaseUrl2 + "hrms/branch_list",
    roleType: BaseUrl2 + "yancy/api/RoleType",
    employeeBranchMapping: BaseUrl2 + "hrms/employee_branch_mapping",
    downloadAll: BaseUrl2 + "hrms/download_all_documents",
    allDocument: BaseUrl2 + "hrms/list_all_documents",
    utilGeoChild: BaseUrl2 + "utils/geo_child",
    promoteToEmp: BaseUrl2 + "hrms/promote2employee",
    reInitiateBvcConsent: BaseUrl2 + "hrms/reinitiate_bvc_consent_sms",
    stateList: BaseUrl2 + "geo/list_state",
    changeApplicantState: BaseUrl2 + "hrms/change_applicant_state",
    UpdateApplicantSalary: BaseUrl2 + "hrms/update_applicant_salary",
    employeeVerficationReport: BaseUrl2 + "hrms/get_empl_verifcation_report",
    downloadZingFile: BaseUrl2 + "zinghr/download_zinghr_xlsx_file?download_zing_xlsx=1",
    empImgDwnld: BaseUrl2 + "hrms/download_hired_candidate_photo",
    creditReportHtmlData : BaseUrl2 + "hrms/credit_report_html_data",

}
const ErpRequest = {
    departmentList: BaseUrl + "api/masters/department_list",
    organisation: BaseUrl + "common/organisation_companies",
    addEmployee: BaseUrl + "admin/add_employee_action",
    userLoginSecurityGroup: BaseUrl + "simple-api/UserLoginSecurityGroup",
    deleteLoginSecurity: BaseUrl + "admin/delete_security_group",
    hrEmployeeList: BaseUrl + "api/employee_list_action",
    securityGroupList: BaseUrl + "simple-api/SecurityGroup",
    addSecurityGroup: BaseUrl + "admin/add_security_group",
    updateEmployee: BaseUrl + "admin/update_employee_action",
    employeeListPassword: BaseUrl2 + "hrms/reset_empl_password",
    activateAccount: BaseUrl2 + "hrms/activate_account"
}
export { ErpRequest }
export default request
