import React from 'react'
import CIcon from '@coreui/icons-react'

import {
  cilCalculator,
} from '@coreui/icons'
import { CNavGroup, CNavItem, CNavTitle } from '@coreui/react'
import { useSelector } from 'react-redux'
const _nav = [

  {
    component: CNavTitle,
    name: '',
  },

  // {
  //   component: CNavGroup,
  //   name: 'SECTION-3',
  //   to: '/dashboard',
  //   icon: <CIcon icon={cilPuzzle} customClassName="nav-icon" />,
  //   items: [
  //     {
  //       component: CNavItem,
  //       name: 'LINK 3.1',
  //       to: '/dashboard',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'LINK 3.2',
  //       to: '/dashboard',
  //     },
  //     {
  //       component: CNavItem,
  //       name: 'LINK 3.3',
  //       to: '/dashboard',
  //     }
  //   ]
  // },
  {
    component: CNavItem,
    name: 'JOB POSTINGS',
    to: '/base/job-post',
    icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  },
  {
    component: CNavItem,
    name: 'ALL APPLICANTS',
    to: '/base/applicant-list',
    icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  },
  // {
  //   component: CNavItem,
  //   name: 'ACTIVITY LOG',
  //   to: '/base/activitylog',
  //   icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  // },
  // {
  //   component: CNavItem,
  //   name: 'TRACK MY LOAN',
  //   to: '/base/track-loan',
  //   icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  // },

  // {
  //   component: CNavItem,
  //   name: 'TICKET DETAILS',
  //   to: '/base/ticket-details',
  //   icon: <CIcon icon={cilCalculator} customClassName="nav-icon" />,
  // },

]
export const _loginPage = {
  logo: "srifin_final.svg",
  loginHeading: "SRIFIN EOS Dashboard",
  ticket_details_url: ""
}

export default _nav
